* {
  box-sizing: border-box;
}

body {
  font-family: "Roboto", sans-serif;
  background-image: url(/images/background.jpg);
  background-repeat: repeat-x;
  background-color: #1f2936;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 2rem;
}

a {
  text-decoration: none;
}

nav a, nav a:visited {
  color: #fff;
}

nav a:hover {
  color: #222f3e;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

div.toc {
  margin-bottom: 3rem;
}

div.year {
  margin-bottom: 1rem;
}

li.month {
  display: inline-block;
}

nav {
  background-color: rgba(63, 214, 250, 0.5) /* #48dbfb */;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  margin-bottom: 2rem;
}

nav a {
  display: block;
}

.menu {
  color: #fff;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 0 4rem;
  max-width: 72rem;
}

.menu__item {
  padding: 1rem 2rem 1rem 2rem;
  text-align: center;
}

.menu__item--active {
  border-bottom: 0.25rem solid white;
}

@media screen and (max-width: 768px) {
  html {
    font-size: 12pt;
  }

  .menu {
    flex-direction: column;
    align-items: center;
  }

  .menu__item {
    margin-bottom: 1rem;
  }

  .menu__item:last-of-type {
    margin-bottom: 0;
  }

}

.title {
  width: 100%;
  margin: 0 auto;
  max-width: 678px;
  text-align: center;
}

.title__text {
  width: 100%;
  height: auto;
}

.title__author {
  font-size: 1.75rem;
  font-weight: 700;
}

main {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 2rem;
}

.payment, .contact {
  background-color: rgba(255, 255, 255, 0.15);
  padding: 1rem;
  width: 100%;
  max-width: 52rem;
  border-radius: 0.5rem;
}

.contact {
  margin-top: 2rem;
}

.payment header {
  margin-bottom: 1rem;
}

.payment__submit {
  display: block;
  background: #48dbfb;
  color: white;
  border-radius: 0.25rem;
  border: 0;
  font-size: 1rem;
  font-weight: 400;
  width: 100%;
  height: 3rem;
  line-height: 3rem;
}

.payment__price {
  font-weight: 300;
  margin-top: 0.5rem;
  border-radius: 0.25rem;
  border: 0;
  padding: 0.75rem;
  padding-left: 2rem;
}

.payment a:visited {
  color: #48dbfb;
}

input, textarea, button {
  outline: none;
}

.payment__container {
  position: relative;
}

.payment__dollar {
  position: absolute;
  color: #000;
  top: 20px;
  left: 12px;
  z-index: 1;
}

.payment__email {
  font-weight: 300;
  font-size: 1rem;
  border-radius: 0.25rem;
  border: 0;
  padding: 10px 12px;
  margin-top: 0.5rem;
  width: 100%;
}

#card-errors {
  margin-top: 0.5rem;
  color: #ee5253;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

footer {
  padding: 1rem;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
}

.container {
  color: #000;
	display: block;
	font-family: "Merriweather", serif;
	font-size: 1em;
	padding-left: 0;
  padding-right: 0;
  width: 100%;
  max-width: 52rem;
	background-color: white;
	padding: 2rem;
  border-radius: 1rem;
  line-height: 1.5;
}

a {
  color: #48dbfb;
}

a:hover {
  color: #000;
}

a:visited {
  color: #aaa;
}

.bibliography h3 {
  font-style: italic;
  margin: 2rem 0 2rem 0;
}

.bibliography h1, .bibliography h2, .bibliography h3 {
  padding-top: 2rem;
  border-top: 1px solid #e6e6e6;
}

.bibliography h1:first-of-type, .bibliography h2:first-of-type, .bibliography h3:first-of-type {
  padding-top: 0;
  border-top: 0;
}

.bibliography img {
  width: 100%;
  height: auto;
}

.bio {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  width: 100%;
  height: auto;
}

.bio__text {
  margin: 2rem;
}

.about {
  padding: 0;
}

.audio {
  max-width: 52rem;
}

.year {
  margin-bottom: 1rem;
}

.year div {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.year li {
  margin-right: 1rem;
}

.form-row {
  padding: 0.25rem;
}

.text__light {
  font-weight: 300;
}

.centered {
  text-align: center;
}

.small {
  font-size: 0.8rem;
}

.contact input, .contact textarea {
  border: 1px solid black;
  padding: 1rem;
  font-weight: 300;
  font-size: 1rem;
  border-radius: 0.25rem;
  border: 0;
  padding: 1rem;
  margin-top: 0.5rem;
  width: 100%;
  display: block;
  margin-bottom: 1rem
}

.contact input[type=submit] {
  display: block;
  background: #48dbfb;
  color: white;
  border-radius: 0.25rem;
  border: 0;
  font-size: 1rem;
  font-weight: 400;
  width: 100%;
  height: 3rem;
}

.contact input[type=submit]:hover, button:hover {
  cursor: pointer;
}

.contact textarea {
  display: block;
  width: 100%;
  resize: none;
  height: 10rem;
}

.contact input[type=submit]:disabled, button:disabled {
  background-color: #aaa;
}